<template>
  <div class="content">
    <div class="conetnt-head">
      <div :style="check?'color:rgba(255, 48, 94, 1);':''" @click="checked(check)"><p>收入</p><p :style="check?'background:rgba(255, 48, 94, 1);':''"></p></div>
      <div :style="!check?'color:rgba(255, 48, 94, 1);':''" @click="checked(check)"><p> 兑换</p><p :style="!check?'background:rgba(255, 48, 94, 1);':''"></p></div>
    </div>
    <template v-if="list.length>0">
      <!-- 兑换列表 -->
      <div v-for="(item,index) in list" :key="index">
        <p>
          <span :style="item.status==6?'color:rgba(255, 48, 94, 1);':''">{{item.remark?item.remark:item.title}}</span>
          <span>{{time(item.time)}}</span>
        </p>
        <p>
          <span>{{item.money?item.money:item.changeMoney}}元</span>
          <span>{{!item.money?'小游戏红包券':'泡泡鱼玩余额'}}</span>
        </p>
        <div></div>
      </div>
    </template>
    <div v-else class="jil">无更多记录</div>
  </div>
</template>
<script>
import game from "../../api/game"
import dayjs from 'dayjs'
export default {
  data() {
    return {
        shibai:false,
        list:[],
        check:true,
        page:1
    };
  },
  mounted(){
    const that =this;
    that.changeBalRecord();
    window.addEventListener("scroll",function(){
      //加载更多
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
        //写后台加载数据的函数
        console.log(1)
        that.page ++;
        if(that.check){
          that.changeBalRecord();
        }else{
          that.changeBalmoney();
        }
      }
    });
  },
  methods: {
    checked(e){
      this.page=1;
      this.check = !e;
      this.list = [];
      if(this.check){
        this.changeBalRecord();
      }else{
        this.changeBalmoney();
      }
    },
    time(item){
      return dayjs(item).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    changeBalmoney(){
      const data={
        page:this.page,
        pageSize:20
      }
      game.changeBalRecord(data).then(res=>{
        this.list.push(...res.list)
      })
    },
    changeBalRecord(){
      const data={
        page:this.page,
        pageSize:20
      }
      game.balanceLogs(data).then(res=>{
        this.list.push(...res.list)
      })
    }
  },
};
</script>
<style scoped>
.content .conetnt-head{
  border-bottom: 1px solid #eeeeee;
  display: flex;
  height: 40px;
}
.content .conetnt-head div{
  width: 40px;
  height: 100%;
  background: #ffffff;
  position: relative;
  padding: 0;
  justify-content: center;
  white-space: nowrap;
}
.content .conetnt-head div p{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content .conetnt-head div p:nth-child(2){
  height: 4px;
  margin-top: -4px;
  width: 20px;
  border-radius: 2px;
}
.content{
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}
.content div {
  height: 70px;
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex-wrap: wrap;
  position: relative;
}
.content div p:nth-child(1) span {
  display: flex;
  justify-content: left;
}
.content div p {
  width: 50%;
}
.content div p span {
  display: flex;
  justify-content: flex-end;
}
.content div div {
  width: calc(100% - 28px);
  height: 1px;
  margin: 0 auto;
  background: #eeeeee;
  position: absolute;
  bottom: 0;
}
.content div p span:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.content div p span:nth-child(1) {
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content div p:nth-child(2) span{
  text-align: right;
}
.content .jil{
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>